module.exports={
  "loadingTitle":                  "กรุณารอสักครู่...",
  "close":                         "ปิด",
  "windowsAuthTitle":              "หน้าต่างการตรวจสอบสิทธิ",
  "invalid":                       "ไม่ถูกต้อง",
  "mustMatch":                     "ต้องตรงกัน",
  "loginSocialButton":             "ล็อคอิน ด้วย {connection:title}",
  "signupSocialButton":            "ลงทะเบียน  ด้วย {connection:title}",
  "networkError":                  "เราไม่สามารถเชื่อมต่อกับเซอร์เวอร์ได้ .<br/>กรุณาลองใหม่อีกครั้ง",
  "noConnectionError":             "ไม่มีการเชื่อมต่อที่ถูกต้องอาจจะพบ",
  "signin": {
    "title":                       "ล็อคอิน",
    "action":                      "เข้าใช้",
    "actionDomain":                "ล็อคอิน ที่ {domain}",
    "all":                         "แสดงทั้งหมด",
    "strategyEmailEmpty":          "ไม่ระบุอีเมล์",
    "strategyEmailInvalid":        "อีเมล์ไม่ถูกต้อง",
    "strategyDomainInvalid":       "โดเมน {domain} ไม่มีการตั้งค่ามาก่อน",
    "signinText":                  "ล็อคอิน",
    "signupText":                  "ลงทะเบียน",
    "forgotText":                  "เปลี่ยนรหัสผ่าน",
    "cancelAction":                "ยกเลิก",
    "footerText":                  "",
    "emailPlaceholder":            "อีเมล์",
    "usernamePlaceholder":         "ชื่อผู้ใช้",
    "passwordPlaceholder":         "รหัสผ่าน",
    "separatorText":               "หรือ",
    "serverErrorText":             "มีข้อผิดพลาดระหว่างทำการล็อคอิน",
    "returnUserLabel":             "ครั้งสุดท้ายที่เข้าใช้งาน คุณใช้...",
    "domainUserLabel":             "คุณได้ทำการเชื่อมต่อ จากเครือข่ายบริษัทของคุณ ...",
    "wrongEmailPasswordErrorText": "อีเมล์ หรือ รหัสผ่านผิด.",
    "unauthorizedErrorText":       "ไม่อนุญาติให้เข้าถึง",
    "or":                          "... หรือ ล็อคอิน โดยใช้ ",
    "loadingMessage":              "กำลังเข้าสู่ระบบ โดย {connection}...",
    "popupCredentials":            "กรอกรหัสผ่าน ในหน้าต่าง Popup",
    "userClosedPopup":             "หน้าต่าง Popup ปิดไป ลองใหม่",
    "userConsentFailed":           "สิทธิการใช้ไม่ได้รับอนุญาติ ลองใหม่",
    "tooManyAttemptsErrorText":    "บัญชีของคุณถูกบล็อกหลังจากที่หลาย ๆ คนพยายามเข้าสู่ระบบติดต่อกัน"
  },
  "signup": {
    "description":                 "",
    "title":                       "ลงทะเบียน",
    "action":                      "ลงทะเบียน",
    "signinText":                  "ล็อคอิน",
    "signupText":                  "ลงทะเบียน",
    "emailPlaceholder":            "อีเมล์",
    "usernamePlaceholder":         "ชื่อผู้ใช้",
    "passwordPlaceholder":         "สร้างรหัสผ่าน",
    "cancelAction":                "ยกเลิก",
    "headerText":                  "กรุณากรอก อีเมล์ และ รหัสผ่าน",
    "footerText":                  "",
    "serverErrorText":             "มีข้อผิดพลาดเกิดขึ้น ระหว่างการลงทะเบียน",
    "userExistsErrorText":         "พบผู้ใช้คนนี้ในระบบอยู่แล้ว",
    "signupOnSSODomainErrorText":  "โดเมน  {domain} ได้ถูกกำหนดค่าสำหรับ Single Sign On และ คุณไม่สามารถสร้างทะเบียนใหม่ได้  ให้เปลียนเป็นการเข้าใช้งานแทน",
    "usernameInUseErrorText":      "ผู้ใช้นี้ กำลังถูกใช้งานอยู่",
    "invalidPassword":             "รหัสผ่านไม่ปลอดภัย",

    "passwordStrength": {
      "nonEmpty": "บังคับไว้รหัสผ่านต้องไม่ ว่าง",
      "lengthAtLeast": "อย่างน้อยต้องมีตัวอักษร จำนวน %d ตัวอักษร ในชุดนี้",
      "shouldContain": "ควรประกอบด้วย:",
      "containsAtLeast" : "อย่างน้อยควรประกอบด้วย %d จากนั้นตามด้วย %d แบบของ อักขระ:",
      "lowerCase": "อักษรเล็ก (a-z)",
      "upperCase": "อักษรใหญ่ (A-Z)",
      "numbers": "ตัวเลข (i.e. 0-9)",
      "specialCharacters" : "อักขระพิเศษ (e.g. !@#$%^&*)",
      "identicalChars": "No more than %d identical characters in a row (e.g., \"%s\" not allowed)"
    }

  },
  "newReset": {
    "title":                       "รีเซ็ตรหัสผ่าน",
    "action":                      "ส่ง",
    "emailPlaceholder":            "อีเมล์",
    "cancelAction":                "ยกเลิก",
    "footerText":                  "",
    "successText":                 "เราได้ทำการส่งอีเมล์ สำหรับ ทำการตั้งรหัสผ่านใหม่",
    "headerText":                  "กรุณากรอกอีเมลของคุณ. เราจะส่งอีเมลให้คุณสามารถตั้งค่ารหัสผ่านของคุณ",
    "serverErrorText":             "มีข้อผิดพลาดเกิดขึ้นระหว่างทำการเปลี่ยนรหัสผ่านใหม่",
    "userDoesNotExistErrorText":   "ไม่พบผู้ใช้นี้",
    "tooManyRequestsErrorText":    "คุณได้มาถึงขีด จำกัด ของความพยายามในการใช้รหัสผ่านรีเซ็ต กรุณารอสักครู่ก่อนที่จะพยายามอีกครั้ง"
  },
  "reset": {
    "title":                       "ตั้งรหัสผ่่านใหม่",
    "action":                      "ส่ง",
    "emailPlaceholder":            "อีเมล์",
    "passwordPlaceholder":         "รหัสผ่านใหม่",
    "repeatPasswordPlaceholder":   "ยืนยันรหัสผ่านใหม่",
    "cancelAction":                "ยกเลิก",
    "footerText":                  "",
    "successText":                 "เราได้ทำการส่งอีเมล์ สำหรับ ทำการตั้งรหัสผ่านใหม่",
    "enterSamePasswordText":       "กรุณากรอก รหัสผ่าน ให้เหมือนกัน",
    "headerText":                  "กรณากรอก อีเมล และ รหัสผ่านใหม่ เราจะทำการส่งอีเมล์ สำหรับยืนยันการเปลียนรหัสผ่านไปให้คุณ ",
    "serverErrorText":             "มีข้อผิดพลาดเกิดขึ้นระหว่างทำการเปลี่ยนรหัสผ่านใหม่",
    "userDoesNotExistErrorText":   "ไม่พบผู้ใช้นี้",
    "tooManyRequestsErrorText":    "คุณได้มาถึงขีด จำกัด ของความพยายามในการใช้รหัสผ่านรีเซ็ต กรุณารอสักครู่ก่อนที่จะพยายามอีกครั้ง",
    "invalidPassword":             "รหัสผ่านไม่ปลอดภัย"
  }
}
