module.exports={
  "//": "This is an automatic translation. Help us to improve it.",
  "loadingTitle": "お待ちください。。。",
  "close": "閉じる",
  "noConnectionError": "有効な接続が見つかりませんでした。",
  "signin": {
    "title": "サインイン",
    "action": "サインイン",
    "all": "すべて表示します。",
    "strategyEmailEmpty": "電子メールが空です。",
    "strategyEmailInvalid": "電子メールは有効です。",
    "strategyDomainInvalid": "{ドメイン} セットアップされていません。",
    "signinText": "サインイン",
    "signupText": "サインアップ",
    "forgotText": "パスワードをお忘れですか？",
    "emailPlaceholder": "電子メール",
    "usernamePlaceholder": "ユーザ名",
    "passwordPlaceholder": "パスワード",
    "separatorText": "または",
    "serverErrorText": "サインインを処理中にエラーがありました。",
    "returnUserLabel": "最後の時間を使用してをサインインして.",
    "wrongEmailPasswordErrorText": "間違ったメール アドレスまたはパスワードです。",
    "or": "... またはを使用してサインイン",
    "loadingMessage": "でサインイン {connection}...",
    "tooManyAttemptsErrorText": "アカウントが複数の連続したログイン試行後にブロックされています。"
  },
  "signup": {
    "description": "",
    "title": "サインアップ",
    "action": "サインアップ",
    "signinText": "サインイン",
    "signupText": "サインアップ",
    "emailPlaceholder": "電子メール",
    "passwordPlaceholder": "パスワードを作成します。",
    "cancelAction": "キャンセル",
    "headerText": "あなたのメール アドレスとパスワードを入力してください。",
    "footerText": "",
    "signupOnSSODomainErrorText": "このドメイン {} が構成されているシングル サインオン ・ アカウントを作成することはできません。代わりにサインインしてください。",
    "serverErrorText": "符号を処理中にエラーがありました。",

    "passwordStrength": {
      "nonEmpty": "パスワードを入力してください",
      "lengthAtLeast": "%d文字以上必要です",
      "shouldContain": "以下の文字を含めてください:",
      "containsAtLeast" : "Contain at least %d of the following %d types of characters:",
      "lowerCase": "半角小文字アルファベット（a-z）",
      "upperCase": "半角大文字アルファベット（A-Z）",
      "numbers": "半角数字（0-9）",
      "specialCharacters" : "Special characters (e.g. !@#$%^&*)",
      "identicalChars": "No more than %d identical characters in a row (e.g., \"%s\" not allowed)"
    }

  },
  "newReset": {
    "title": "パスワードのリセット",
    "action": "送信",
    "emailPlaceholder": "電子メール",
    "cancelAction": "キャンセル",
    "footerText": "",
    "successText": "我々 はちょうどあなたのパスワードをリセットするメールを送信しました。",
    "headerText": "あなたのメールアドレスを入力してください。私たちはあなたのパスワードをリセットするためのメールが送信されます。",
    "serverErrorText": "パスワードのリセットを処理中にエラーがありました。",
    "userDoesNotExistErrorText": "使用者はいません。",
    "tooManyRequestsErrorText": "あなたはパスワードリセット試行の制限に達しました。再試行する前に待機してください。"
  },
  "reset": {
    "title": "パスワードを変更する",
    "action": "送信",
    "emailPlaceholder": "電子メール",
    "passwordPlaceholder": "新しいパスワード",
    "repeatPasswordPlaceholder": "新しいパスワードを確認します。",
    "cancelAction": "キャンセル",
    "footerText": "",
    "successText": "我々 はちょうどあなたのパスワードをリセットするメールを送信しました。",
    "enterSamePasswordText": "同じパスワードを入力してください。",
    "headerText": "あなたのメール アドレスと新しいパスワードを入力してください。我々 は、パスワードの変更を確認するためにメールが送信されます。",
    "serverErrorText": "パスワードのリセットを処理中にエラーがありました。",
    "userDoesNotExistErrorText": "使用者はいません。",
    "tooManyRequestsErrorText": "あなたはパスワードリセット試行の制限に達しました。再試行する前に待機してください。",
    "invalidPassword": "パスワードが弱すぎます。"
  }
}
