module.exports={
  "loadingTitle":                  "Proszę czekać...",
  "close":                         "Zamknij",
  "windowsAuthTitle":              "Windows Authentication",
  "invalid":                       "Nieprawidłowy",
  "mustMatch":                     "Musi być zgodny",
  "loginSocialButton":             "Zaloguj się z {connection:title}",
  "signupSocialButton":            "Zarejestruj się z {connection:title}",
  "networkError":                  "Nie mogliśmy nawiązać połączenia z serwerem.<br/>Spróbuj ponownie później.",
  "noConnectionError":             "Nie ważne połączenia można znaleźć.",
  "signin": {
    "title":                       "Zaloguj się",
    "action":                      "Zaloguj",
    "actionDomain":                "Zaloguj się do {domain}",
    "all":                         "Pokaż wszystko",
    "strategyEmailEmpty":          "Email pusty.",
    "strategyEmailInvalid":        "Niepoprawny email.",
    "strategyDomainInvalid":       "Domena {domain} nie została skonfigurowana.",
    "signinText":                  "Zaloguj się",
    "signupText":                  "Zarejestruj się",
    "forgotText":                  "Zresetuj hasło",
    "cancelAction":                "Anuluj",
    "footerText":                  "",
    "emailPlaceholder":            "Email",
    "usernamePlaceholder":         "Nazwa użytkownika",
    "passwordPlaceholder":         "Hasło",
    "separatorText":               "albo",
    "serverErrorText":             "Wystąpił błąd podczas logowania.",
    "returnUserLabel":             "Ostatnio zalogowałeś się za pomocą...",
    "domainUserLabel":             "Jesteś zalogowany za pomocą sieci firmowej...",
    "wrongEmailPasswordErrorText": "Niepoprawny email lub hasło.",
    "unauthorizedErrorText":       "Odmowa dostępu.",
    "or":                          "... albo zaloguj używając",
    "loadingMessage":              "Zaloguj za pomocą {connection}...",
    "popupCredentials":            "Podaj swoje dane dostępowe w oknie",
    "userClosedPopup":             "Okno zamknięte. Spróbuj ponownie.",
    "userConsentFailed":           "Uprawnienia nie zostały przyznane. Spróbuj ponownie.",
    "tooManyAttemptsErrorText":    "Twoje konto zostało zablokowane po wielu kolejnych próbach logowania."
  },
  "signup": {
    "description":                 "",
    "title":                       "Zarejestruj się",
    "action":                      "Zarejestruj się",
    "signinText":                  "Zaloguj się",
    "signupText":                  "Zarejestruj się",
    "emailPlaceholder":            "Email",
    "usernamePlaceholder":         "Nazwa użytkownika",
    "passwordPlaceholder":         "Podaj hasło",
    "cancelAction":                "Anuluj",
    "headerText":                  "Wpisz swój email oraz hasło",
    "footerText":                  "",
    "serverErrorText":             "Wystąpił błąd podczas rejestracji.",
    "userExistsErrorText":         "Użytkownik już istnieje.",
    "signupOnSSODomainErrorText":  "Domena {domain} została skonfigurowana dla pojedyńczego logowania, nie możesz utworzyć konta. Spróbuj się zalogować.",
    "usernameInUseErrorText":      "Nazwa użytkownika już istnieje.",
    "invalidPassword":             "Hasło jest za słabe.",

    "passwordStrength": {
      "nonEmpty": "Hasło nie powinno być puste",
      "lengthAtLeast": "Hasło musi składać się z conajmniej %d znaków",
      "shouldContain": "Powinno zawierać:",
      "containsAtLeast" : "Powinno zawierać conajmniej %d z następujących %d znaków:",
      "lowerCase": "Małe litery (a-z)",
      "upperCase": "Wielkie litery (A-Z)",
      "numbers": "Liczby (np. 0-9)",
      "specialCharacters" : "Znaki specjalne (np. !@#$%^&*)",
      "identicalChars": "Nie więcej niż %d identycznych znaków może znajdować się koło siebie (np., \"%s\" jest nie dozwolone)"
    }

  },
  "newReset": {
    "title":                       "Reset hasła",
    "action":                      "Wyślij",
    "emailPlaceholder":            "Email",
    "cancelAction":                "Porzuć",
    "footerText":                  "",
    "successText":                 "Wysłaliśmy Ci email, abyś potwierdził zmianę hasła.",
    "headerText":                  "Podaj swój adres e-mail. Wyślemy Ci e-mail, aby zresetować hasło.",
    "serverErrorText":             "Wystąpił błąd podczas resetowania hasła.",
    "userDoesNotExistErrorText":   "Użytkownik nie istnieje.",
    "tooManyRequestsErrorText":    "Osiągnięto limit prób resetowania hasła. Proszę czekać przed ponowną próbą."
  },
  "reset": {
    "title":                       "Zmień swoje hasło",
    "action":                      "Wyślij",
    "emailPlaceholder":            "Email",
    "passwordPlaceholder":         "Nowe hasło",
    "repeatPasswordPlaceholder":   "Potwierdź nowe hasło",
    "cancelAction":                "Porzuć",
    "footerText":                  "",
    "successText":                 "Wysłaliśmy Ci email, abyś potwierdził zmianę hasła.",
    "enterSamePasswordText":       "Wprowadź proszę takie samo hasło.",
    "headerText":                  "Wprowadź proszę swój adres email i nowe hasło. Po chwili dostaniesz email z potwierdzeniem zmiany hasła.",
    "serverErrorText":             "Wystąpił błąd podczas resetowania hasła.",
    "userDoesNotExistErrorText":   "Użytkownik nie istnieje.",
    "tooManyRequestsErrorText":    "Osiągnięto limit prób resetowania hasła. Proszę czekać przed ponowną próbą.",
    "invalidPassword":             "Hasło jest za słabe."
  }
}
