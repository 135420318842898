module.exports={
  "loadingTitle":                 "Lütfen bekleyiniz...",
  "close":                        "Kapat",
  "windowsAuthTitle":             "Windows Doğrulama",
  "invalid":                      "Geçersiz",
  "mustMatch":                    "Eşleşmeli",
  "loginSocialButton":            "{connection:title} ile Giriş Yap",
  "signupSocialButton":           "{connection:title} lie Kayıt Ol",
  "noConnectionError":            "Hiçbir geçerli bağlantı bulunamadı.",
  "signin": {
    "title":                      "Giriş",
    "action":                     "Erişim",
    "all":                        "Hepsini Göster",
    "strategyEmailEmpty":         "Bu e-posta boş",
    "strategyEmailInvalid":       "Bu e-posts geçersiz",
    "strategyDomainInvalid":      "{domain} Bu alan henüz kurulmadı",
    "signinText":                 "Giriş",
    "signupText":                 "Kayıt Ol",
    "forgotText":                 "Şifremi unuttum",
    "emailPlaceholder":           "E-posta",
    "usernamePlaceholder":        "Kullanıcı Adı",
    "passwordPlaceholder":        "Şifre",
    "separatorText":              "veya",
    "serverErrorText":            "Kayıt işlemi sırasında bir hatayla karşılaşıldı",
    "returnUserLabel":            "... ile en son girişiniz ",
    "domainUserLabel":            "Şirketinize ait ... ağından bağlandınız",
    "wrongEmailPasswordErrorText":"Hatalı e-posta veya şifre.",
    "or":                         "veya bunlardan biriyle Giriş Yap",
    "loadingMessage":             "{connection} ile Giriş Yap",
    "popupCredentials":           "Lütfen açılır pencereye kimlik belgelerinizi giriniz",
    "userClosedPopup":            "Açılır pencere kapandı. Lütfen tekrar deneyiniz.",
    "userConsentFailed":          "İzin verilmedi. Lütfen tekrar deneyiniz.",
    "tooManyAttemptsErrorText":   "Hesabınız birden fazla ardışık giriş denemeden sonra bloke edilmiştir."
  },
  "signup": {
    "description":                "",
    "title":                      "Kayıt Ol",
    "action":                     "Kayıt Ol",
    "signinText":                 "Giriş",
    "signupText":                 "Kayıt Ol",
    "emailPlaceholder":           "E-posta",
    "passwordPlaceholder":        "Şifre Oluşturun",
    "cancelAction":               "İptal",
    "headerText":                 "Lütfen e-postanızı ve şifrenizi giriniz",
    "footerText":                 "",
    "signupOnSSODomainErrorText": "Bu alan {domain} üzerindeki tek oturum için yapılandırılmıştır, bu yüzden hesap oluşturamazsınız. Bunun yerine, lütfen giriş yapmayı deneyiniz.",
    "serverErrorText":            "Kayıt olurken bir hata ile karşılaşıldı.",
    "userExistsErrorText":        "Bu kullanıcı adı zaten alınmış.",
    "invalidPassword":            "Bu şifre çok zayıf."
  },
  "newReset": {
    "title":                      "Şifreyi Sıfırla",
    "action":                     "Yolla",
    "emailPlaceholder":           "E-posta",
    "cancelAction":               "İptal",
    "footerText":                 "",
    "successText":                "Size şifrenizi sıfırlamanız için bir e-posta yolladık.",
    "headerText":                 "Lütfen e-mail adresinizi giriniz. Size şifrenizi sıfırlamak için bir e-posta göndereceğiz.",
    "serverErrorText":            "Şifre sıfırlama işlemi sırasında bir hatayla karşılaşıldı.",
    "userDoesNotExistErrorText":  "Böyle bir kullanıcı bulunamadı.",
    "tooManyRequestsErrorText":   "Parola sıfırlama girişimleri sınırına ulaştınız. tekrar denemeden önce lütfen bekleyin."
  },
  "reset": {
    "title":                      "Şifre değiştirme",
    "action":                     "Yolla",
    "emailPlaceholder":           "E-posta",
    "passwordPlaceholder":        "Yeni Şifre",
    "repeatPasswordPlaceholder":  "Yeni Şifreyi Onayla",
    "cancelAction":               "İptal",
    "footerText":                 "",
    "successText":                "Size şifrenizi sıfırlamanız için bir e-posta yolladık.",
    "enterSamePasswordText":      "Lütfen aynı şifreyi tekrar giriniz.",
    "headerText":                 "Lütfen e-posta adresinizi ve yeni şifrenizi giriniz. Size şifre değişikliğini onaylamak için bir e-posta yollayacağız.",
    "serverErrorText":            "Şifre sıfırlama işlemi sırasında bir hatayla karşılaşıldı.",
    "userDoesNotExistErrorText":  "Böyle bir kullanıcı bulunamadı.",
    "tooManyRequestsErrorText":   "Parola sıfırlama girişimleri sınırına ulaştınız. tekrar denemeden önce lütfen bekleyin.",
    "invalidPassword":            "Şifre çok zayıf."
  }
}
