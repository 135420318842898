module.exports = (function anonymous(locals, filters, escape, rethrow
/**/) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('<div class="a0-reset a0-mode">\n    <form id="a0-change_password" class="form-reset-password" novalidate>\n        <div class="a0-body-content">\n          <div class="a0-options">\n              <a href="javascript: {}" class="a0-centered a0-btn-small a0-back">\n                <svg width="10px" height="10px" enable-background="new 0 0 24 24" version="1.0" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <polyline fill="none" points="12.5,21 3.5,12 12.5,3 " stroke="#000000" stroke-miterlimit="10" stroke-width="2"></polyline> <line fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="2" x1="22" x2="3.5" y1="12" y2="12"></line> </svg>\n              </a>\n          </div>\n          <div class="a0-collapse-reset">\n              <div class="a0-header a0-instructions">', escape((10,  i18n.t('newReset:headerText') )), '</div>\n          </div>\n          <div class="a0-emailPassword">\n              <div class="a0-inputs-wrapper">\n                <div class="a0-inputs">\n\n                    <div class="a0-email">\n                        ');17;
                          var emailPlaceholder = i18n.t('signin:emailPlaceholder')
                            + ( _isUsernameRequired() ? ' / ' + i18n.t('signin:usernamePlaceholder') : '' )
                          var inputBudicon = _isUsernameRequired() || 'username' === usernameStyle ? '1' : '5';
                        ; buf.push('\n                        <label for="a0-reset_easy_email" class="a0-sad-placeholder">\n                          ', escape((23,  emailPlaceholder )), '\n                        </label>\n                        <div class="a0-input-box">\n                          <i class="a0-icon-budicon-', escape((26,  inputBudicon )), '"></i>\n                          <input name="email"\n                               id="a0-reset_easy_email"\n                               type="email"\n                               value=""\n                               placeholder="', escape((31,  emailPlaceholder )), '"\n                               title="', escape((32,  emailPlaceholder )), '">\n                        </div>\n                    </div>\n                </div>\n              </div>\n          </div>\n        </div>\n\n        <div class="bottom-content">\n          ');41; if (i18n.t('newReset:footerText') && i18n.t('newReset:footerText').length) { ; buf.push('\n          <div class="a0-reset-footer">\n              <small>\n                  ', (44,  i18n.t('newReset:footerText') ), '\n              </small>\n          </div>\n          ');47; } ; buf.push('\n          <div class="a0-action">\n              <button type="submit" class="a0-primary a0-next" style="width: 100%; background-color: ', escape((49,  primaryColor )), '">', escape((49,  i18n.t('newReset:action') )), '</button>\n          </div>\n        </div>\n    </form>\n</div>\n'); })();
} 
return buf.join('');
})